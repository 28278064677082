import React from 'react';
import ReactDOM from 'react-dom';

import Hub from './components/hub';

ReactDOM.render(
  <React.StrictMode>
     <Hub />
  </React.StrictMode>,
  document.getElementById('root')
);


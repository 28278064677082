import React, { Component } from 'react'
import Select from 'react-select';

class Selector extends Component {
  constructor(props){
    super(props)
    this.state = {
      values: props.values,
      placeholder: props.placeholder,
      changeValue:props.changeValue,
      olts: props.runningOlts
    }
  }

  render() {
    var _placeholder = this.state.placeholder;
    var options = [];

    if(this.state.values === 'olt'){

      _placeholder = this.state.olts.find(element => (element.ip === this.state.placeholder));

      this.state.olts.forEach(element => {
        options.push({value:element.ip, label:element.name})
      });

    }
    else{
      this.state.values.forEach(element => {
        options.push({value:element, label:element})
      });
    }

    return (
      <Select 
          placeholder={_placeholder === undefined ? null: _placeholder.name} 
          defaultValue={_placeholder === undefined ? null: _placeholder.ip}
          onChange={(e) => {this.state.changeValue(e.value);}}
          options={options}
      />
    )  
  }
}

export default Selector
import React, { Component } from 'react';
import DatePicker from "../../datepicker";
import Selector from '../../selector';

import { IoIosCheckmarkCircleOutline } from "@react-icons/all-files/io/IoIosCheckmarkCircleOutline";
import { IoIosCloseCircleOutline } from "@react-icons/all-files/io/IoIosCloseCircleOutline";
import { IoMdCreate } from "@react-icons/all-files/io/IoMdCreate";
import { IoIosArrowDropupCircle} from "react-icons/io";
import { IoIosArrowDropdownCircle} from "react-icons/io";
import { IoIosCheckboxOutline} from "react-icons/io";
import { IoIosCheckbox} from "react-icons/io";

import './index.css';

class ItemManutencao extends Component {

    constructor(props){
        super(props)

        this.state = {
            adding : props.adding,
            editing: props.editing,
            changeParentEditing : props.changeEditing,
            changeParentSelected : props.changeSelected,
            rowTemp: props.row,
            row: props.row,
            selected: props.selected,
            runningOlts:props.runningOlts
        }

        this.changeEditing = this.changeEditing.bind(this);
        this.changeSelected = this.changeSelected.bind(this);

    }

    changeEditing(forceEditing){

    if(forceEditing){
        this.state.changeParentEditing(this.state.rowTemp);
      }
      else{

        if(this.state.editing){
            this.state.changeParentEditing();
        }
        else{
            this.state.changeParentEditing(this.state.rowTemp);
            
        }   

      }
    }

    changeSelected(){
        this.setState({selected:!this.state.selected});
        this.state.changeParentSelected(this.state.row.idmanutencao);
    }

    render() {
        //
        var currentOlt = this.state.runningOlts === undefined ? null : this.state.runningOlts.find(element => (element.ip === this.state.row.equipamento));

        if(this.state.editing){
            return (
                <>
                    <tr>
                        <td className="text-center align-middle">
                            <DatePicker changeDate={(e)=> {this.setState({rowTemp: { ...this.state.rowTemp, inicio: e}})}}  enable={this.state.adding ? !this.state.adding :this.state.editing} className="form-control input-edit" date={(this.state.row.inicio === null ||this.state.row.inicio === undefined) ? new Date(): new Date(this.state.row.inicio)}/>
                         </td>
                        <td className="text-center align-middle"><Selector changeValue={(e) => {this.setState({rowTemp: { ...this.state.rowTemp, equipamento: e}});}} placeholder={this.state.row.equipamento} runningOlts={this.state.runningOlts} values={'olt'}/></td>
                        <td className="text-center align-middle"><Selector changeValue={(e) => {this.setState({rowTemp: { ...this.state.rowTemp, slot: e}});}} placeholder={this.state.row.slot} values={[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]}/></td>
                        <td className="text-center align-middle"><Selector changeValue={(e) => {this.setState({rowTemp: { ...this.state.rowTemp, port: e}});}} placeholder={this.state.row.port} values={[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]}/></td>
                        <td className="text-center align-middle">
                            <DatePicker changeDate={(e)=> {this.setState({rowTemp: { ...this.state.rowTemp, finalizacao: e}})}}  enable={this.state.editing} className="form-control input-edit" date={this.state.row.finalizacao === null ||this.state.row.finalizacao === undefined? new Date(): new Date(this.state.row.finalizacao)}/></td>
                        <td className="text-center align-middle">{this.state.row.online === 'S' ? <IoIosArrowDropupCircle size="20" className="icon green" />: <IoIosArrowDropdownCircle size="20" className="icon red" />}</td>
                        <td className="text-center align-middle">
                            <IoIosCheckmarkCircleOutline className="icon" data-toggle="modal" data-target={this.state.adding === true ? '#pendingAdd' :'#change' +this.state.row.equipamento + this.state.row.slot + this.state.row.port} size="25" onClick={() =>{this.changeEditing(true)}}/>
                        </td>
                        <td className="text-center align-middle">
                            < IoIosCloseCircleOutline  className="icon" size="25" onClick={() => {this.changeEditing(false)}}/>
                        </td>
                    </tr>
                </>
            )
        }
        else{
            return (
                <>
                    <tr>
                        <td className="text-center align-middle"><DatePicker enable={this.state.editing} className="form-control input-edit" date={new Date(this.state.row.inicio) }/></td>
                        <td className="text-center align-middle">{currentOlt === undefined ? null : currentOlt.name}</td>
                        <td className="text-center align-middle">{this.state.row.slot}</td>
                        <td className="text-center align-middle">{this.state.row.port}</td>
                        <td className="text-center align-middle">{this.state.row.finalizacao === null ? '-' :<DatePicker enable={this.state.editing} className="form-control input-edit" date={new Date(this.state.row.finalizacao)}/>}</td>
                        <td className="text-center align-middle">{this.state.row.online === 'S' ? <IoIosArrowDropupCircle size="20" className="icon green" />: <IoIosArrowDropdownCircle size="20" className="icon red" />}</td>
                        <td className="text-center align-middle"><IoMdCreate  className="icon" size="20" onClick={() => {this.changeEditing()}}/></td>
                        <td className="text-center align-middle">
                            {this.state.selected ? 
                            <IoIosCheckbox  data-toggle="modal" data-target={'#delete' + this.state.row.name} className="icon" size="20" onClick={() => {this.changeSelected();}}/>
                            :
                            <IoIosCheckboxOutline  data-toggle="modal" data-target={'#delete' + this.state.row.name} className="icon" size="20" onClick={() => {this.changeSelected();}}/>
                            }
                            </td>
                    </tr>
                </>
            )
        }
        
    }
}

export default ItemManutencao
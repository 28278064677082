import React from 'react'
import Table from '../../components/table'

function Manutencoes() {
    
  return (
    <Table table='manutencoes' header={['Inicio','Equipamento','Slot','Port','Previsão','Status']}/>
  )
}

export default Manutencoes 
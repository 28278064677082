import React, { Component } from 'react'
import requests from '../../requests/requests'
import './index.css';
import ItemManutencao from '../itens/item_manutencao';
import { IoIosAddCircleOutline } from "@react-icons/all-files/io/IoIosAddCircleOutline";
import { IoIosCloseCircleOutline } from "@react-icons/all-files/io/IoIosCloseCircleOutline";
import { IoLogoDropbox } from "react-icons/io";

import Modal from '../modal'
 
class Table extends Component {

  constructor(props){
    super(props)
    this.state = {
      adding:false,
      editing:{
        status:false,
        row:{}
      },
      header: props.header,
      table: props.table,
      selectedId:[],
      rowsManutencao:[],
      runningOlts:[]
    }

    this.reloadDB = this.reloadDB.bind(this);
    this.changeEditing = this.changeEditing.bind(this);
    this.changeAdding = this.changeAdding.bind(this);
    this.changeSelected = this.changeSelected.bind(this);
    this.updateRow = this.updateRow.bind(this);
    this.archiveMaintence = this.archiveMaintence.bind(this);
    
  }

  async reloadDB(){
    var runningOlts;

    async function reload() {
      manutencaoDb = await requests.getMaintences(false);
      runningOlts = await requests.selectConfig('olt');
    }

    var manutencaoDb = await requests.getMaintences(false);
    runningOlts = await requests.selectConfig('olt');

    for (let i = 0; i < 2; i++) {
      if(manutencaoDb.status === 'success'){
        break;
      }

      await setTimeout(reload, 5000);

    }

    if(manutencaoDb.status === undefined || manutencaoDb.status === 'error'){
      manutencaoDb=[];
    }

    if(runningOlts.status === undefined || runningOlts.status === 'error'){
      runningOlts.content=[];
    }

    manutencaoDb.result.forEach((element,index) => {
      var path = element.path.split('/');
      manutencaoDb.result[index].slot = path[1];
      manutencaoDb.result[index].port = path[2];
    });
    
    this.setState({rowsManutencao : manutencaoDb.result});
    this.setState({runningOlts : runningOlts.content});
  }

  async componentDidMount() {
    await this.reloadDB();
  }

  async updateRow(){
    var row = this.state.editing.row;
    var filter;
    var path;
    switch (this.state.table) {
      case 'manutencoes':
        if(typeof row.idmanutencao != 'number'){
          return;
        }
        
        filter = row.idmanutencao;

        if(row.slot === undefined || row.port === undefined)
        {
          path = undefined;
        }
        else{
          path = '0/' + row.slot + '/' + row.port
        }

        row = {
          inicio: row.inicio === undefined? null: new Date(row.inicio).toISOString().slice(0, 19).replace('T', ' '),
          equipamento:row.equipamento === undefined?null : row.equipamento,
          online:row.online === undefined? null: row.online,
          path: path === undefined? null: path,
          finalizacao: (row.finalizacao === undefined || row.finalizacao === null)? null: new Date(row.finalizacao).toISOString().slice(0, 19).replace('T', ' '),
          quedas: row.quedas === undefined? null: row.quedas
        }
        console.log(row)
        var teste = await requests.changeMaintence(filter,row)
        alert(teste.status);
        this.changeEditing();
        this.reloadDB();
        break;
    
      default:
        return;
    }

    
    
  }

  async insertRow(){
    var row = this.state.editing.row;
    switch (this.state.table) {
      case 'manutencoes':

        row = {
          equipamento:row.equipamento === undefined?null : row.equipamento,
          slot: row.slot === undefined? null: row.slot,
          port: row.port === undefined? null: row.port,
          previsao: row.finalizacao === undefined? null: row.finalizacao
        }

        await requests.downMaintence(row)
        alert('success');
        this.changeAdding();
        this.reloadDB();
        break;
    
      default:
        return;
    }

  }

  async archiveMaintence(justificativa,tipo){
    var row = {};
    row.finalizacao = new Date();
    row.inicio = new Date();
    row.tipo = tipo;
    row.justificativa = justificativa;

    for (let i = 0; i < this.state.rowsManutencao.length; i++) {
      const element = this.state.rowsManutencao[i];

      if((element.inicio < row.inicio) && (this.state.selectedId.find(element => (element.idmanutencao === row.idmanutencao)) !== undefined)){
        row.inicio = element.inicio;
      }
      
    }
    const event = await requests.insertEvent(row);

    this.state.selectedId.forEach(async (element) => {
      await requests.changeMaintence(element,{idocorrencia:event.insertId});
    });

    await this.setState({selectedId:[]});
    await this.reloadDB();
    
  }

  changeEditing(row){
    if(row === undefined){
      this.setState({editing:{status:false,row:{}}})
    }
    else{
      if(!this.state.adding)
        this.setState({selectedId:[],editing:{status:true,row:row}})
    }
  }

  changeSelected(id){

    if(this.state.adding || this.state.editing.status){
      return;
    }
    var selected = this.state.selectedId;

    const found = selected.find(element => element === id);

    if(found === undefined){
      selected.push(id);
    }
    else{
      selected.splice(selected.indexOf(id,0),1);
    }
    
    this.setState({selectedId:selected});
  }

  changeAdding(row){

    if(row === undefined){
      this.setState({adding:!this.state.adding});
      this.setState({editing:{status:false,row:{}}})
    }
    else{
      this.setState({adding:true})
      this.setState({selectedId:[],editing:{status:true,row:row}})
    }
  }

  render() {
    var header = [];
    var item = [];
    var modal = [];
    
    this.state.header.forEach(element => { 
      header.push(<th key={'head' + element} className="text-center align-middle display-4"scope="col">{element}</th>);
    });

    header.push(<th key={'afterhead1'} className="text-center align-middle display-4"scope="col">
      {null}
    </th>);

    if(this.state.selectedId.length === 0)
    {
      header.push(<th key={'afterhead2'} className="text-center align-middle display-4" scope="col">
      {this.state.adding? < IoIosCloseCircleOutline  className="icon" size="25" onClick={() => {this.changeAdding()}}/> : < IoIosAddCircleOutline  className="icon" size="25" onClick={() => {this.changeAdding()}}/>}
    </th>);
    }
    else{
      header.push(<th key={'afterhead2'} className="text-center align-middle display-4"scope="col">
      <IoLogoDropbox  className="icon" size="25" data-toggle="modal" data-target="#pendingEvent" />
    </th>);
    }
    
   
    switch (this.state.table) {
      case 'manutencoes':
        item.push(this.state.adding ? <ItemManutencao key={'pendingAdd'}runningOlts={this.state.runningOlts}  row={{}} editing={true} adding={true} changeEditing={this.changeAdding}/>: null)
        modal.push(<Modal key={'pendingAddModal'} id={'pendingAdd'} message='Tem certeza que deseja realizar esta inserção?' tittle='Adicionar nova Manutenção' acceptHandle={async () => {this.insertRow() }}/>)
        modal.push(<Modal key={'pendingEvent'} event={true} id={'pendingEvent'} message='Preencha as informações sobre o evento' tittle='Adicionar novo evento' acceptHandle={(e) => {this.archiveMaintence(e.justificativa,e.tipo)}}/>)

        this.state.rowsManutencao.forEach(element => {
          var ownId = element.equipamento + element.slot + element.port;
          var isSelected = this.state.selectedId.find(e => e === element.idmanutencao) !== undefined ? true : false; 
          item.push(<ItemManutencao runningOlts={this.state.runningOlts} key={ownId} row={element} selected={isSelected} editing={this.state.editing.row.idmanutencao === element.idmanutencao ? true : false} changeSelected={this.changeSelected} changeEditing={this.changeEditing}/>)
          modal.push(<Modal key={'change' + ownId} id={'change' + ownId} message='Tem certeza que deseja realizar esta alteração?' tittle='Alterar configurações da Manutenção' acceptHandle={()=>{this.updateRow(); }}/>)
        });
        break;
      default:
        item = 'Something is going wrong';
        break;
    }

    return (
      <div className="App">
          <table 
           key={Math.random().toString()}
           id='table'
           data-url="json/data1.json"
           data-filter-control="true"
           data-show-search-clear-button="true"
           className="w-100 table table-hover table-striped">
            <thead>
              <tr>
                {header}
              </tr>
            </thead>
            <tbody>
              {item}
            </tbody>
          </table>
          {modal}
    </div>
    )
  }
}

export default Table




import React, { Component } from 'react'
import Hydra from '../../sources/logos/hydra.svg';
import './index.css';
import Manutencoes from '../../pages/manutencoes';
import Home from '../../pages/home';
import Clientes from '../../pages/clientes';
class hub extends Component {

    constructor(props){
        super(props)
        this.state = {
            page: 'manutencoes'
        }
        this.changePage = this.changePage.bind(this);
    }

    changePage(_page){
        this.setState({page:_page});
    }
    
  render() {
      
    let pagina;

    switch(this.state.page){
        case 'home':
            pagina = <Home/>
            break;
        case 'manutencoes':
            pagina = <Manutencoes/>
            break;
        case 'clientes':
            pagina = <Clientes/>
            break;
        default:
            pagina = <p1>Erro!</p1>
    }
    return (
        <>
        <nav className="navbar navbar-expand-lg navbar-light menu">
        <a className="navbar-brand" href="/#"><img alt="Sherlock" className="logo" src={Hydra}></img></a>
    
        <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
                <li className="nav-item active">
                    <label className={this.state.page === 'home' ? 'container active':'container'} onClick={() => this.changePage('home')}>Home
                    </label>
                    
                </li>
                <li className="nav-item active">
                    <label className={this.state.page === 'clientes' ? 'container active':'container'} onClick={() => this.changePage('clientes')}>Clientes
                    </label>
                    
                </li>
                <li className="nav-item">
                    <label className={this.state.page === 'manutencoes' ? 'container active':'container'} onClick={() => this.changePage('manutencoes')}>Manutenções
                    </label> 
                    
                </li>
            </ul>
        </div>
        </nav>
        {pagina}
    </>
    )
  }
}

export default hub
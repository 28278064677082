const axios = require('axios');
require('dotenv').config()

class requests {
    
    static getMaintences = async (archive) => {
        var config = {
            method: 'get',
            url: 'http://10.168.168.132:4131/maintences/select',
            headers: { 'Content-Type': 'application/json' },

            data: JSON.stringify({archive: archive === true ? true : false})
          };
          
        const response = await axios(config)
            .then(function (response) {
                return (response.data);
            })
            .catch(function (error) {
                return ({status:'error',error:error});
            });

        return response;
       
    };

    static changeMaintence = async (idmanutencao,content) => {

        var _content = [];
        Object.keys(content).map(function (key) { 
            var current = {};
            current.column = key;
            current.item = content[key];

            _content.push(current);
            return current;
        });

        var config = {
            method: 'post',
            url: 'http://10.168.168.132:4131/maintences/update',
            headers: { 'Content-Type': 'application/json' },

            data: JSON.stringify({idmanutencao: idmanutencao,content:_content})
          };
          
        const response = await axios(config)
            .then(function (response) {
                return (response.data);
            })
            .catch(function (error) {
                return ({status:'error',error:error});
            });

        return response;
       
    };

    static downMaintence = async (maintence) => {
        var row = maintence;
        
        var body = {
            ip: row.equipamento,
            slot: row.slot,
            port: row.port,
            previsao: row.previsao,
            type: 'OLT'
        };
        console.log(body)
        var config = {
            method: 'post',
            url: 'http://10.168.168.132:4131/alerts/down',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(body)
          };
          
        const response = await axios(config)
            .then(function (response) {
                return (response.data);
            })
            .catch(function (error) {
                return ({status:'error',error:error});
            });

        console.log(response)

        return response;
       
    };

    static upMaintence = async (maintence) => {

        var config = {
            method: 'post',
            url: 'http://10.168.168.132:4131/alerts/up',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(maintence)
          };
          
        const response = await axios(config)
            .then(function (response) {
                return (response.data);
            })
            .catch(function (error) {
                return ({status:'error',error:error});
            });

        return response;
       
    };

    static insertEvent = async (row) => {
        var config = {
            method: 'post',
            url: 'http://10.168.168.132:4131/events/insert',
            headers: { 'Content-Type': 'application/json' },
            data: [JSON.stringify(row)]
          };
          
        const response = await axios(config)
            .then(function (response) {
                return (response.data);
            })
            .catch(function (error) {
                return ({status:'error',error:error});
            });
        console.log(response);

        return response;
       
    };

    static selectConfig = async (collection) => {
        var config = {
            method: 'post',
            url: 'http://10.168.168.132:4131/config/select',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({collection: collection})
          };
          
        const response =await axios(config)
            .then(function (response) {
                return (response.data);
            })
            .catch(function (error) {
                return ({status:'error',error:error});
            });

        return response;
       
    };

  }

  export default requests;
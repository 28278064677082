import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import './index.css';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
registerLocale('pt', pt)
setDefaultLocale('pt');

function Datepicker(props) {
        const _data = new Date(props.date);
        const [startDate, setStartDate] = useState(
        props.editing ? null : setHours(setMinutes(_data, _data.getMinutes()), _data.getHours())
        );

    if(props.enable)
    {
        return (
            <DatePicker
              locale="pt"
              selected={startDate}
              onChange={(date) => {setStartDate(date); props.changeDate(date);}}
              showTimeSelect
              timeIntervals={10}
              dateFormat="MMMM d, yyyy hh:mm aa"
              className="form-control input-edit-date"
            />
          );

    }else{
        return (
            <DatePicker
            locale="pt"
              selected={startDate}              showTimeSelect
              disabled
              dateFormat="MMMM d, yyyy hh:mm aa"
              className="form-control input-edit-date"
            />
          );

    }
    
  };

export default Datepicker;

import React , { useState } from 'react'
import Selector from '../selector';

import './index.css';
function Modal(props) {

    const [tipo, setTipo] = useState('Rompimento');
    const [justificativa, setJustificativa] = useState('');
    
    
    return (
        <>
            <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{props.tittle}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setTipo('Rompimento'); setJustificativa('');}}>
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        {props.event === true ? 
                        <>
                            <p>Selecione o tipo de evento</p>
                            <Selector changeValue={(e) => {setTipo(e);}} value={tipo}  placeholder='Rompimento' values={['Rompimento','Queda de energia','Alarme Falso']}/>
                            <br/>
                            <p>Justificativa de conclusão</p>
                            <input type="text" placeholder={'Digite aqui a justificativa'} className="form-control input-edit" value={justificativa} onChange={(e) => {setJustificativa(e.target.value);}}/>
                        </>
                        :
                        props.message}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" data-dismiss="modal" onClick={()=>{props.acceptHandle({justificativa: justificativa,tipo: tipo})}}>Finalizar</button>
                        <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={()=>{setJustificativa('');setTipo('Rompimento');}}>Cancelar</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal
